/* Container für Drinks und Bestellübersicht */
.container {
  max-width: 1200px;  /* Maximalbreite für den Hauptcontainer */
  margin: 0 auto;     /* Zentriert den Container */
  padding: 0 20px;
}

/* Header-Container für Logo und Überschrift */
.header-container {
  max-width: 1200px;  /* Gleiche Breite wie der Hauptcontainer */
  margin: 0 auto;     /* Zentriert den Header-Container */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
}

.logo {
  height: 80px; /* Größe des Logos */
}

.page-header {
  font-size: 2rem;
  text-align: right;
  flex-grow: 1; /* Zentriert die Überschrift */
  margin: 0;
}
/* Gesamtcontainer */
.container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  height: 100vh; /* Volle Höhe des Bildschirms einnehmen */
}

/* Drink-Liste - 70% der Bildschirmbreite */
.drinks-list {
  flex: 0 0 70%; /* 70% der Breite für die Drinks */
}

/* Drink-Grid */
.drinks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));  /* Variable Spaltenanzahl */
  gap: 20px;
  width: 100%;
}

/* Drink-Item Styling - Quadratisch */
.drink-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  aspect-ratio: 1 / 1;  /* Sicherstellen, dass die Items quadratisch sind */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hover-Effekt für Vergrößerung */
.drink-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Overlay mit höherer Transparenz für weniger auffällige Bilder */
.overlay {
  background-color: rgba(0, 0, 0, 0.7); /* Mehr Transparenz, damit das Bild weniger auffällig ist */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Text im Card Body */
.card-body {
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 1;
  padding: 10px;
  text-align: center;
}

.card-title {
  font-size: 1.5rem; /* Größere Schriftgröße für den Titel */
  font-weight: bold;  /* Fett (bold) für den Drink-Namen */
  margin-bottom: 5px;
}

.card-text {
  font-size: 1.25rem; /* Größere Schriftgröße für den Text */
}

/* Bestellübersicht - 30% der Breite */
.order-summary {
  flex: 0 0 30%; /* 30% der Breite für die Bestellungsliste */
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 120px); /* Höhe abzüglich der Überschrift */
}

/* Scrollbare Bestellübersicht */
.order-items {
  flex-grow: 1; /* Platz für das Scrollen */
  overflow-y: auto; /* Scrollbar, wenn der Platz nicht ausreicht */
  margin-bottom: 10px; /* Abstand zum Bestellabschluss-Button */
}

/* Bestellabschluss-Button immer unten */
.order-summary button {
  margin-top: auto; /* Button ganz unten */
}

/* Benachrichtigungs-Overlay am unteren Bildschirmrand */
.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  font-size: 1.25rem;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeInOut 2.5s forwards;
  z-index: 1000;
}

/* Erfolgsnachricht in grün */
.notification.success {
  background-color: #28a745;
  color: white;
}

/* Fehlermeldung in rot */
.notification.error {
  background-color: #dc3545;
  color: white;
}

/* Nachricht in der Warteschlange in Orange */
.notification.queue {
  background-color: #ffa500; /* Orange für Warteschlange */
  color: white;
}

/* Einfache Fade-In- und Fade-Out-

Animation */
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .drinks-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 Spalten für kleinere Bildschirme */
  }
}

@media (max-width: 576px) {
  .drinks-grid {
    grid-template-columns: 1fr; /* 1 Spalte für sehr kleine Bildschirme */
  }
}